export class ProductUnitFakeData {
    public static units = [
      {
        id: 1,
        unit: 'Pcs',
      },
      {
        id: 2,
        unit: 'Kg',
      },
      {
        id: 3,
        unit: 'Box',
      },
      {
        id: 4,
        unit: 'Tons',
      }
     
    ];
  }
  