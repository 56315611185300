export class DriverFakeData {
    public static drivers = [
      {
        id: 1,
        code:'0001',
        licensenumber:'18293874884944',
        licenseexpired:'2020/05/21',
        licensecreate:'2020/05/21',
        idcard: '3410400203708',
        drivername: 'นายสุริยัน ศรีมาตย์',
        email: 'suriyan@ssj.co.th',
        phone: '0982531845',
        address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
        contact:'วรรณวิภา คุณแผน',
        contactphone:'0863178563',
        filenamedoc:'',
        filenamepic:'',
        status: 'Active',
        avatar: 'assets/images/avatars/1.png'
      },
      {
        id: 2,
        code:'0002',
        licensenumber:'18293874884944',
        licenseexpired:'2020/05/21',
        licensecreate:'2020/05/21',
        idcard: '3410400203708',
        drivername: 'นายสุริยัน ศรีมาตย์',
        email: 'suriyan@ssj.co.th',
        phone: '0982531845',
        address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
        contact:'วรรณวิภา คุณแผน',
        contactphone:'0863178563',
        filenamedoc:'',
        filenamepic:'',
        status: 'Active',
        avatar: 'assets/images/avatars/2.png'
      }
    ];
  }
  