export class CustomerFakeData {
    public static customers = [
      {
        id: 1,
        code:'0001',
        shortname:'SSJ',
        fullname: 'บริษัท เอส เอส เจ เทคโนโลยี จำกัด',
        email: 'suriyan@ssj.co.th',
        phone: '0982531845',
        address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
        website: 'https://www.ssj.co.th',
        tax: '01055557687',
        contact: 'สุริยัน ศรีมาตย์',
        department: 'ฝ่ายขาย',
        bankaccout:'ไทยพานิชย์',
        bankaccounttype:'ออมทรัพย์',
        paymethod:'โอน',
        location:'',
        filenamedoc:'',
        filenamepic:'',
        status: 'Disable',
        avatar: 'assets/images/avatars/1.png'
      },
      {
        id: 2,
        code:'0002',
        shortname:'SSJ',
        fullname: 'บริษัท เอส เอส เจ เทคโนโลยี จำกัด',
        email: 'suriyan@ssj.co.th',
        phone: '0982531845',
        address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
        website: 'https://www.ssj.co.th',
        tax: '01055557687',
        contact: 'สุริยัน ศรีมาตย์',
        department: 'ฝ่ายขาย',
        bankaccout:'ไทยพานิชย์',
        bankaccounttype:'ออมทรัพย์',
        paymethod:'โอน',
        location:'',
        filenamedoc:'',
        filenamepic:'',
        status: 'Active',
        avatar: 'assets/images/avatars/2.png'
      },
      {
        id: 3,
        code:'0003',
        shortname:'SSJ',
        fullname: 'บริษัท เอส เอส เจ เทคโนโลยี จำกัด',
        email: 'suriyan@ssj.co.th',
        phone: '0982531845',
        address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
        website: 'https://www.ssj.co.th',
        tax: '01055557687',
        contact: 'สุริยัน ศรีมาตย์',
        department: 'ฝ่ายขาย',
        bankaccout:'ไทยพานิชย์',
        bankaccounttype:'ออมทรัพย์',
        paymethod:'โอน',
        location:'',
        filenamedoc:'',
        filenamepic:'',
        status: 'Active',
        avatar: 'assets/images/avatars/3.png'
      },
      {
        id: 4,
        code:'0004',
        shortname:'SSJ',
        fullname: 'บริษัท เอส เอส เจ เทคโนโลยี จำกัด',
        email: 'suriyan@ssj.co.th',
        phone: '0982531845',
        address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
        website: 'https://www.ssj.co.th',
        tax: '01055557687',
        contact: 'สุริยัน ศรีมาตย์',
        department: 'ฝ่ายขาย',
        bankaccout:'ไทยพานิชย์',
        bankaccounttype:'ออมทรัพย์',
        paymethod:'โอน',
        location:'',
        filenamedoc:'',
        filenamepic:'',
        status: 'Disable',
        avatar: 'assets/images/avatars/4.png'
      },
      {
        id: 5,
        code:'0005',
        shortname:'SSJ',
        fullname: 'บริษัท เอส เอส เจ เทคโนโลยี จำกัด',
        email: 'suriyan@ssj.co.th',
        phone: '0982531845',
        address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
        website: 'https://www.ssj.co.th',
        tax: '01055557687',
        contact: 'สุริยัน ศรีมาตย์',
        department: 'ฝ่ายขาย',
        bankaccout:'ไทยพานิชย์',
        bankaccounttype:'ออมทรัพย์',
        paymethod:'โอน',
        location:'',
        filenamedoc:'',
        filenamepic:'',
        status: 'Active',
        avatar: 'assets/images/avatars/5.png'
      },
      {
        id: 6,
        code:'0006',
        shortname:'SSJ',
        fullname: 'บริษัท เอส เอส เจ เทคโนโลยี จำกัด',
        email: 'suriyan@ssj.co.th',
        phone: '0982531845',
        address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
        website: 'https://www.ssj.co.th',
        tax: '01055557687',
        contact: 'สุริยัน ศรีมาตย์',
        department: 'ฝ่ายขาย',
        bankaccout:'ไทยพานิชย์',
        bankaccounttype:'ออมทรัพย์',
        paymethod:'โอน',
        location:'',
        filenamedoc:'',
        filenamepic:'',
        status: 'Active',
        avatar: ''
      },
      {
        id: 6,
        code:'0007',
        shortname:'SSJ',
        fullname: 'บริษัท เอส เอส เจ เทคโนโลยี จำกัด',
        email: 'suriyan@ssj.co.th',
        phone: '0982531845',
        address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
        website: 'https://www.ssj.co.th',
        tax: '01055557687',
        contact: 'สุริยัน ศรีมาตย์',
        department: 'ฝ่ายขาย',
        bankaccout:'ไทยพานิชย์',
        bankaccounttype:'ออมทรัพย์',
        paymethod:'โอน',
        location:'',
        filenamedoc:'',
        filenamepic:'',
        status: 'Active',
        avatar: 'assets/images/avatars/7.png'
      },
      {
        id: 8,
        code:'0008',
        shortname:'SSJ',
        fullname: 'บริษัท เอส เอส เจ เทคโนโลยี จำกัด',
        email: 'suriyan@ssj.co.th',
        phone: '0982531845',
        address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
        website: 'https://www.ssj.co.th',
        tax: '01055557687',
        contact: 'สุริยัน ศรีมาตย์',
        department: 'ฝ่ายขาย',
        bankaccout:'ไทยพานิชย์',
        bankaccounttype:'ออมทรัพย์',
        paymethod:'โอน',
        location:'',
        filenamedoc:'',
        filenamepic:'',
        status: 'Disable',
        avatar: 'assets/images/avatars/8.png'
      },
      {
        id: 9,
        code:'0009',
        shortname:'SSJ',
        fullname: 'บริษัท เอส เอส เจ เทคโนโลยี จำกัด',
        email: 'suriyan@ssj.co.th',
        phone: '0982531845',
        address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
        website: 'https://www.ssj.co.th',
        tax: '01055557687',
        contact: 'สุริยัน ศรีมาตย์',
        department: 'ฝ่ายขาย',
        bankaccout:'ไทยพานิชย์',
        bankaccounttype:'ออมทรัพย์',
        paymethod:'โอน',
        location:'',
        filenamedoc:'',
        filenamepic:'',
        status: 'Active',
        avatar: ''
      },
      {
        id: 10,
        code:'0010',
        shortname:'SSJ',
        fullname: 'บริษัท เอส เอส เจ เทคโนโลยี จำกัด',
        email: 'suriyan@ssj.co.th',
        phone: '0982531845',
        address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
        website: 'https://www.ssj.co.th',
        tax: '01055557687',
        contact: 'สุริยัน ศรีมาตย์',
        department: 'ฝ่ายขาย',
        bankaccout:'ไทยพานิชย์',
        bankaccounttype:'ออมทรัพย์',
        paymethod:'โอน',
        location:'',
        filenamedoc:'',
        filenamepic:'',
        status: 'Active',
        avatar: 'assets/images/avatars/10.png'
      },
      {
        id: 11,
        code:'0011',
        shortname:'SSJ',
        fullname: 'บริษัท เอส เอส เจ เทคโนโลยี จำกัด',
        email: 'suriyan@ssj.co.th',
        phone: '0982531845',
        address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
        website: 'https://www.ssj.co.th',
        tax: '01055557687',
        contact: 'สุริยัน ศรีมาตย์',
        department: 'ฝ่ายขาย',
        bankaccout:'ไทยพานิชย์',
        bankaccounttype:'ออมทรัพย์',
        paymethod:'โอน',
        location:'',
        filenamedoc:'',
        filenamepic:'',
        status: 'Disable',
        avatar: 'assets/images/avatars/11.png'
      },
      {
        id: 12,
        code:'0012',
        shortname:'SSJ',
        fullname: 'บริษัท เอส เอส เจ เทคโนโลยี จำกัด',
        email: 'suriyan@ssj.co.th',
        phone: '0982531845',
        address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
        website: 'https://www.ssj.co.th',
        tax: '01055557687',
        contact: 'สุริยัน ศรีมาตย์',
        department: 'ฝ่ายขาย',
        bankaccout:'ไทยพานิชย์',
        bankaccounttype:'ออมทรัพย์',
        paymethod:'โอน',
        location:'',
        filenamedoc:'',
        filenamepic:'',
        status: 'Active',
        avatar: 'assets/images/avatars/12.png'
      },
      {
        id: 13,
        code:'0013',
        shortname:'SSJ',
        fullname: 'บริษัท เอส เอส เจ เทคโนโลยี จำกัด',
        email: 'suriyan@ssj.co.th',
        phone: '0982531845',
        address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
        website: 'https://www.ssj.co.th',
        tax: '01055557687',
        contact: 'สุริยัน ศรีมาตย์',
        department: 'ฝ่ายขาย',
        bankaccout:'ไทยพานิชย์',
        bankaccounttype:'ออมทรัพย์',
        paymethod:'โอน',
        location:'',
        filenamedoc:'',
        filenamepic:'',
        status: 'Active',
        avatar: ''
      },
      {
        id: 14,
        code:'0014',
        shortname:'SSJ',
        fullname: 'บริษัท เอส เอส เจ เทคโนโลยี จำกัด',
        email: 'suriyan@ssj.co.th',
        phone: '0982531845',
        address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
        website: 'https://www.ssj.co.th',
        tax: '01055557687',
        contact: 'สุริยัน ศรีมาตย์',
        department: 'ฝ่ายขาย',
        bankaccout:'ไทยพานิชย์',
        bankaccounttype:'ออมทรัพย์',
        paymethod:'โอน',
        location:'',
        filenamedoc:'',
        filenamepic:'',
        status: 'Disable',
        avatar: ''
      },
      {
        id: 15,
        code:'0015',
        shortname:'SSJ',
        fullname: 'บริษัท เอส เอส เจ เทคโนโลยี จำกัด',
        email: 'suriyan@ssj.co.th',
        phone: '0982531845',
        address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
        website: 'https://www.ssj.co.th',
        tax: '01055557687',
        contact: 'สุริยัน ศรีมาตย์',
        department: 'ฝ่ายขาย',
        bankaccout:'ไทยพานิชย์',
        bankaccounttype:'ออมทรัพย์',
        paymethod:'โอน',
        location:'',
        filenamedoc:'',
        filenamepic:'',
        status: 'Disable',
        avatar: 'assets/images/avatars/10.png'
      },
      {
        id: 16,
        code:'0016',
        shortname:'SSJ',
        fullname: 'บริษัท เอส เอส เจ เทคโนโลยี จำกัด',
        email: 'suriyan@ssj.co.th',
        phone: '0982531845',
        address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
        website: 'https://www.ssj.co.th',
        tax: '01055557687',
        contact: 'สุริยัน ศรีมาตย์',
        department: 'ฝ่ายขาย',
        bankaccout:'ไทยพานิชย์',
        bankaccounttype:'ออมทรัพย์',
        paymethod:'โอน',
        location:'',
        filenamedoc:'',
        filenamepic:'',
        status: 'Disable',
        avatar: ''
      },
      {
        id: 17,
        code:'0017',
        shortname:'SSJ',
        fullname: 'บริษัท เอส เอส เจ เทคโนโลยี จำกัด',
        email: 'suriyan@ssj.co.th',
        phone: '0982531845',
        address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
        website: 'https://www.ssj.co.th',
        tax: '01055557687',
        contact: 'สุริยัน ศรีมาตย์',
        department: 'ฝ่ายขาย',
        bankaccout:'ไทยพานิชย์',
        bankaccounttype:'ออมทรัพย์',
        paymethod:'โอน',
        location:'',
        filenamedoc:'',
        filenamepic:'',
        status: 'Disable',
        avatar: ''
      },
      {
        id: 18,
        code:'0018',
        shortname:'SSJ',
        fullname: 'บริษัท เอส เอส เจ เทคโนโลยี จำกัด',
        email: 'suriyan@ssj.co.th',
        phone: '0982531845',
        address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
        website: 'https://www.ssj.co.th',
        tax: '01055557687',
        contact: 'สุริยัน ศรีมาตย์',
        department: 'ฝ่ายขาย',
        bankaccout:'ไทยพานิชย์',
        bankaccounttype:'ออมทรัพย์',
        paymethod:'โอน',
        location:'',
        filenamedoc:'',
        filenamepic:'',
        status: 'Disable',
        avatar: 'assets/images/avatars/10.png'
      },
      {
        id: 19,
        code:'0019',
        shortname:'SSJ',
        fullname: 'บริษัท เอส เอส เจ เทคโนโลยี จำกัด',
        email: 'suriyan@ssj.co.th',
        phone: '0982531845',
        address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
        website: 'https://www.ssj.co.th',
        tax: '01055557687',
        contact: 'สุริยัน ศรีมาตย์',
        department: 'ฝ่ายขาย',        bankaccout:'ไทยพานิชย์',
        bankaccounttype:'ออมทรัพย์',
        paymethod:'โอน',
        location:'',
        filenamedoc:'',
        filenamepic:'',

        status: 'Disable',
        avatar: 'assets/images/avatars/9.png'
      },
      {
        id: 20,
        code:'0020',
        shortname:'SSJ',
        fullname: 'บริษัท เอส เอส เจ เทคโนโลยี จำกัด',
        email: 'suriyan@ssj.co.th',
        phone: '0982531845',
        address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
        website: 'https://www.ssj.co.th',
        tax: '01055557687',
        contact: 'สุริยัน ศรีมาตย์',
        department: 'ฝ่ายขาย',
        bankaccout:'ไทยพานิชย์',
        bankaccounttype:'ออมทรัพย์',
        paymethod:'โอน',
        location:'',
        filenamedoc:'',
        filenamepic:'',
        status: 'Disable',
        avatar: ''
      }
      
    ];
  }
  