import { InMemoryDbService } from 'angular-in-memory-web-api';

import { accountSettingsFakeData } from '@fake-db/account-settings.data';
import { BlogFakeData } from '@fake-db/blog.data';
import { CalendarFakeData } from '@fake-db/calendar.data';
import { CardAnalyticsData } from '@fake-db/card-analytics.data';
import { CardStatisticsData } from '@fake-db/card-statistics.data';
import { ChatWidgetFakeData } from '@fake-db/chat-widget.data';
import { ChatFakeData } from '@fake-db/chat.data';
import { DashboardFakeData } from '@fake-db/dashboard.data';
import { DatatableFakeData } from '@fake-db/datatables';
import { EcommerceFakeData } from '@fake-db/ecommerce.data';
import { EmailFakeData } from '@fake-db/email.data';
import { FAQFakeData } from '@fake-db/faq.data';
import { InvoiceFakeData } from '@fake-db/invoice.data';
import { KBFakeData } from '@fake-db/knowledge-base.data';
import { NotificationsFakeData } from '@fake-db/notifications.data';
import { PricingFakeData } from '@fake-db/pricing.data';
import { ProfileFakeData } from '@fake-db/profile.data';
import { SearchFakeData } from '@fake-db/search.data';
import { TodoFakeData } from '@fake-db/todo.data';
import { UsersFakeData } from '@fake-db/users.data';
import { ProductFakeData } from '@fake-db/product.data';
import { CustomerFakeData } from '@fake-db/customer.data';
import { SupplierFakeData } from '@fake-db/supplier.data';
import { TruckFakeData } from '@fake-db/truck.data';
import { SparepartFakeData } from '@fake-db/sparepart.data';
import { DriverFakeData } from '@fake-db/driver.data';
import { PetroFakeData } from '@fake-db/petro.data';
import { StonemillFakeData } from '@fake-db/stonemill.data';
import { SandpitFakeData } from '@fake-db/sandpit.data';
import { CatalogFakeData } from '@fake-db/product-catalog.data';
import { ProductUnitFakeData } from '@fake-db/product-unit.data';
export class FakeDbService implements InMemoryDbService {
  createDb(): any {
    return {
      // Data-table
      'datatable-rows': DatatableFakeData.rows,

      // Account Settings
      'account-settings-data': accountSettingsFakeData.data,

      // Knowledge Base
      'knowledge-base-data': KBFakeData.data,

      // Faq
      'faq-data': FAQFakeData.data,

      // Pricing
      'pricing-data': PricingFakeData.data,

      // Blog
      'blog-data': BlogFakeData.data,

      // Profile
      'profile-data': ProfileFakeData.data,

      // Card Statistics
      'card-statistics-data': CardStatisticsData.data,

      // Card Analytics
      'card-analytics-data': CardAnalyticsData.data,

      // Users
      'users-data': UsersFakeData.users,

      // Product
      'product-data': ProductFakeData.products,

      // Customer
      'customer-data': CustomerFakeData.customers,

      // Supplier
      'supplier-data': SupplierFakeData.suppliers,

      // Truck
      'truck-data': TruckFakeData.trucks,

      // Sparepart
      'sparepart-data': SparepartFakeData.spareparts,

      // Driver
      'driver-data': DriverFakeData.drivers,

      // Petro
      'petro-data': PetroFakeData.petros,

      // Stonemill
      'stonemill-data': StonemillFakeData.stonemills,
      // Sandpit
      'sandpit-data': SandpitFakeData.sandpits,
      // product catalog
      'product-catalog-data': CatalogFakeData.catalogs,
      // product catalog
      'product-unit-data': ProductUnitFakeData.units,

      // Invoice
      'invoice-data': InvoiceFakeData.invoices,

      // Todo
      'todos-data': TodoFakeData.tasks,
      'todos-assignee': TodoFakeData.assignee,
      'todos-filters': TodoFakeData.filters,
      'todos-tags': TodoFakeData.tags,

      // Email
      'emails-data': EmailFakeData.emails,
      'email-folders': EmailFakeData.folders,
      'email-labels': EmailFakeData.labels,

      // E-Commerce
      'ecommerce-products': EcommerceFakeData.products,
      'ecommerce-relatedProducts': EcommerceFakeData.relatedProducts,
      'ecommerce-userWishlist': EcommerceFakeData.userWishlist,
      'ecommerce-userCart': EcommerceFakeData.userCart,

      // Chat
      'chat-profileUser': ChatFakeData.profileUser,
      'chat-contacts': ChatFakeData.contacts,
      'chat-chats': ChatFakeData.chats,

      // Chat Widget
      'chat-widget-data': ChatWidgetFakeData.data,

      // Calendar
      'calendar-events': CalendarFakeData.events,
      'calendar-filter': CalendarFakeData.calendar,

      // Search
      'search-data': SearchFakeData.search,

      // Notifications
      'notifications-data': NotificationsFakeData.data,

      // Dashboard
      'dashboard-data': DashboardFakeData.data
    };
  }
}
