import { CoreMenu } from "@core/types";
export const menu: CoreMenu[] = [
  {
    id: "overview",
    title: "Overview",
    translate: "MENU.OVERVIEW.OVERVIEW",
    type: "collapsible",
    icon: "layout",
    role: ['Admin'],
    children: [
      {
        id: "summary",
        title: "Summary",
        translate: "MENU.OVERVIEW.SUMMARY",
        type: "item",
        icon: "circle",
        url: "dashboard",
      },
    ],
  },
  {
    id: "building",
    title: "Building",
    translate: "MENU.APPS.BUILDING",
    type: "item",
    icon: "box",
    url: "pages/building",
    role: ['Admin'],
  },
  {
    id: "village",
    title: "Village",
    translate: "MENU.APPS.VILLAGE",
    type: "item",
    icon: "home",
    url: "pages/village",
    role: ['Admin'],
  },
  {
    id: "port",
    title: "Port",
    translate: "MENU.APPS.PORT",
    type: "item",
    icon: "cpu",
    url: "pages/port",
    role: ['Admin','Customer'],
  },
  {
    id: "reseved",
    title: "Reseved",
    translate: "MENU.APPS.RESEVED",
    type: "item",
    icon: "copy",
    url: "pages/reseved",
    role: ['Admin','user'],
  },
  {
    id: "users",
    title: "Users management",
    translate: "MENU.APPS.USERS",
    type: "item",
    icon: "users",
    url: "apps/user/user-list",
    role: ['Admin'],
  },
  {
    id: "logout",
    title: "Logout",
    translate: "MENU.APPS.LOGOUT",
    type: "item",
    icon: "log-out",
    url: "pages/authentication/logout",
  },
];
