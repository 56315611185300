export const locale = {
  lang: "th",
  data: {
    MENU: {
      OVERVIEW: {
        OVERVIEW: "ภาพรวม",
        SUMMARY: "ข้อสรุป",
      },
      APPS: {
        MESSAGES: "ข้อความ",
        BUILDING: "ตึก/อาคาร",
        VILLAGE: "หมู่บ้าน",
        RESEVED: "การจอง",
        PORT: "พอร์ต",
        USERS: "จัดการผู้ใช้งาน",
        SETTINGS: "การตั้งค่า",
        LOGOUT: "ออกจากระบบ",
      },
    },
  },
};
