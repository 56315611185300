export class TruckFakeData {
    public static trucks = [
      {
        id: 1,
        carnumber:'8กฒ2618',
        brand: 'HONDA',
        carprovince: 'กรุงเทพ',
        catalog: 'รถบรรทุก พ่วง',
        color: 'สีดำคริสตัล (มุก) ',
        model: 'CIVIC 1.8 E',
        machine:'น้ำมัน',
        oiltank:'47',
        machinecode:'R18Z1',
        vehicletype: 'FC6', //รหัสตัวถังรถ
        price:'6,000,000',
        gearcode:'GDZA',
        gearsystem:'อัตโนมัติ CVT',
        modelyear:'2017',
        weight:'1227',
        dimention:'12x2.55x4',
        tiresize:'215/55R16',
        numberseats:'5',
        filenamedoc:'',
        filenamepic:'',
        status: 'Active',
        avatar: ''
      },
      {
        id: 2,
        carnumber:'8กฒ2618',
        brand: 'HONDA',
        carprovince: 'กรุงเทพ',
        catalog: 'รถบรรทุก พ่วง',
        color: 'สีดำคริสตัล (มุก) ',
        model: 'CIVIC 1.8 E',
        machine:'น้ำมัน',
        oiltank:'47',
        machinecode:'R18Z1',
        vehicletype: 'FC6', 
        price:'6,000,000',
        gearcode:'GDZA',
        gearsystem:'อัตโนมัติ CVT',
        modelyear:'2017',
        weight:'1227',
        dimention:'12x2.55x4',
        tiresize:'215/55R16', 
        numberseats:'5',
        filenamedoc:'',
        filenamepic:'',
        status: 'Active',
        avatar: ''
      }

    ];
  }
  