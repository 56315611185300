export class SandpitFakeData {
    public static sandpits = [
        {
            id: 1,
            code:'0001',
            shortname:'SSJ',
            name: 'บริษัท เอส เอส เจ เทคโนโลยี จำกัด',
            email: 'suriyan@ssj.co.th',
            phone: '0982531845',
            address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
            website: 'https://www.ssj.co.th',
            tax: '01055557687',
            contact: 'สุริยัน ศรีมาตย์',
            department: 'ฝ่ายขาย',
            bankaccout:'ไทยพานิชย์',
            bankaccounttype:'ออมทรัพย์',
            paymethod:'โอน',
            location:'',
            filenamedoc:'',
            filenamepic:'',
            status: 'Active',
            avatar: 'assets/images/avatars/1.png'
          },
          {
            id: 2,
            code:'0002',
            shortname:'SSJ',
            name: 'บริษัท เอส เอส เจ เทคโนโลยี จำกัด',
            email: 'suriyan@ssj.co.th',
            phone: '0982531845',
            address: '2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10250',
            website: 'https://www.ssj.co.th',
            tax: '01055557687',
            contact: 'สุริยัน ศรีมาตย์',
            department: 'ฝ่ายขาย',
            bankaccout:'ไทยพานิชย์',
            bankaccounttype:'ออมทรัพย์',
            paymethod:'โอน',
            location:'',
            filenamedoc:'',
            filenamepic:'',
            status: 'Disable',
            avatar: 'assets/images/avatars/2.png'
          }
    ];
  }
  