export const environment = {
  production: false,
  hmr: false,
  apiUrl:"https://www.3rd.ths-true.com/api/v1",
  baseUrl:"https://www.3rd.ths-true.com/api/v1",
  imagePath:"https://www.3rd.ths-true.com/uploads",
  ssoBaseUrl:"https://www.3rd.ths-true.com/api/v1",

  // apiUrl:"http://localhost:4000/api",
  // baseUrl:"http://localhost:4000/api",
  // imagePath:"http://localhost:4000/upload",
  // ssoBaseUrl:"http://localhost:4000/api",
  
  sysName:"True",
  sysVersion:"1.0",
  client_id:"korn-client",
  client_secret:"20bd501c-7276-4c1f-a687-b08d8779174e",
  regis_secret:"dm1zbG9jYWw6UEBzc3cwcmQ=",
  //https://www.google.com/recaptcha/admin/site/620060705
  siteKey:"6LdcYfUkAAAAADHk6FCHLNs2Xt4kSONZs4puwzTf",
  //siteKey:"6LdcT9cnAAAAADqLfnrVetVxpSeUDOfwj1B_3lVh",
  realm_id:"korntracking",
  grant_type:"password"


  
    // authorize_code : "3MAUBO2uMDBegKGP4l44qF",
    // user_auth : "gis_3rd",
    // client_id : "524428872",
    // "scope": [
    //   "read","update","create","delete"
    // ]


    // //smartui_3rd
    // //mNWyc*$++64uahPJ
    
    // user_auth : "smartui_3rd",
    // authorize_code : "LAyAXZ4F4oz13fKAhOSV9H"
    // client_id : "366114863",
    // "scope": [
    //   "read","update","create","delete"
    // ]

};
