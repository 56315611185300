export class ProductFakeData {
    public static products = [
      {
        id: 1,
        model: 'Model A',
        partname: 'Part name A',
        partno: 'Part no A',
        price: '1000',
        lotsize: '10',
        unit: 'Kg',
        max: '10',
        min: '100',
        status: 'Disable',
        avatar: ''
      },
      {
        id: 2,
        model: 'Model B',
        partname: 'Part name B',
        partno: 'Part no B',
        price: '1000',
        lotsize: '10',
        unit: 'Kg',
        max: '10',
        min: '100',
        status: 'Disable',
        avatar: 'assets/images/avatars/10.png'
      },
      {
        id: 3,
        model: 'Model C',
        partname: 'Part name C',
        partno: 'Part no C',
        price: '1000',
        lotsize: '10',
        unit: 'Kg',
        max: '10',
        min: '100',
        status: 'Active',
        avatar: 'assets/images/avatars/1.png'
      },
      {
        id: 4,
        model: 'Model D',
        partname: 'Part name D',
        partno: 'Part no D',
        price: '1000',
        lotsize: '10',
        unit: 'Kg',
        max: '10',
        min: '100',
        status: 'Disable',
        avatar: 'assets/images/avatars/9.png'
      },
      {
        id: 5,
        model: 'Model E',
        partname: 'Part name E',
        partno: 'Part no E',
        price: '1000',
        lotsize: '10',
        unit: 'Kg',
        max: '10',
        min: '100',
        status: 'Disable',
        avatar: 'assets/images/avatars/10.png'
      },
      {
        id: 6,
        model: 'Model F',
        partname: 'Part name F',
        partno: 'Part no F',
        price: '1000',
        lotsize: '10',
        unit: 'Kg',
        max: '10',
        min: '100',
        status: 'Active',
        avatar: ''
      },
      {
        id: 7,
        model: 'Model G',
        partname: 'Part name G',
        partno: 'Part no G',
        price: '1000',
        lotsize: '10',
        unit: 'Kg',
        max: '10',
        min: '100',
        status: 'Disable',
        avatar: ''
      },
      {
        id: 8,
        model: 'Model H',
        partname: 'Part name H',
        partno: 'Part no H',
        price: '1000',
        lotsize: '10',
        unit: 'Kg',
        max: '10',
        min: '100',
        status: 'Disable',
        avatar: 'assets/images/avatars/9.png'
      },
      {
        id: 9,
        model: 'Model I',
        partname: 'Part name I',
        partno: 'Part no I',
        price: '1000',
        lotsize: '10',
        unit: 'Kg',
        max: '10',
        min: '100',
        status: 'Disable',
        avatar: 'assets/images/avatars/2.png'
      },
      {
        id: 10,
        model: 'Model J',
        partname: 'Part name J',
        partno: 'Part no J',
        price: '1000',
        lotsize: '10',
        unit: 'Kg',
        max: '10',
        min: '100',
        status: 'Disable',
        avatar: 'assets/images/avatars/9.png'
      },
      {
        id: 11,
        model: 'Model K',
        partname: 'Part name K',
        partno: 'Part no K',
        price: '1000',
        lotsize: '10',
        unit: 'Kg',
        max: '10',
        min: '100',
        status: 'Disable',
        avatar: 'assets/images/avatars/4.png'
      },
      {
        id: 12,
        model: 'Model L',
        partname: 'Part name L',
        partno: 'Part no L',
        price: '1000',
        lotsize: '10',
        unit: 'Kg',
        max: '10',
        min: '100',
        status: 'Disable',
        avatar: 'assets/images/avatars/5.png'
      },
      {
        id: 13,
        model: 'Model M',
        partname: 'Part name M',
        partno: 'Part no M',
        price: '1000',
        lotsize: '10',
        unit: 'Kg',
        max: '10',
        min: '100',
        status: 'Disable',
        avatar: 'assets/images/avatars/7.png'
      },
      {
        id: 14,
        model: 'Model N',
        partname: 'Part name N',
        partno: 'Part no N',
        price: '1000',
        lotsize: '10',
        unit: 'Kg',
        max: '10',
        min: '100',
        status: 'Active',
        avatar: 'assets/images/avatars/9.png'
      },
      {
        id: 15,
        model: 'Model O',
        partname: 'Part name O',
        partno: 'Part no O',
        price: '1000',
        lotsize: '10',
        unit: 'Kg',
        max: '10',
        min: '100',
        status: 'Active',
        avatar: 'assets/images/avatars/2.png'
      },
      {
        id: 16,
        model: 'Model P',
        partname: 'Part name P',
        partno: 'Part no P',
        price: '1000',
        lotsize: '10',
        unit: 'Kg',
        max: '10',
        min: '100',
        status: 'Active',
        avatar: 'assets/images/avatars/6.png'
      },
      {
        id: 17,
        model: 'Model Q',
        partname: 'Part name Q',
        partno: 'Part no Q',
        price: '1000',
        lotsize: '10',
        unit: 'Kg',
        max: '10',
        min: '100',
        status: 'Active',
        avatar: ''
      },
      {
        id: 18,
        model: 'Model R',
        partname: 'Part name R',
        partno: 'Part no R',
        price: '1000',
        lotsize: '10',
        unit: 'Kg',
        max: '10',
        min: '100',
        status: 'Active',
        avatar: 'assets/images/avatars/7.png'
      },
      {
        id: 19,
        model: 'Model S',
        partname: 'Part name S',
        partno: 'Part no S',
        price: '1000',
        lotsize: '10',
        unit: 'Kg',
        max: '10',
        min: '100',
        status: 'Active',
        avatar: ''
      },
      {
        id: 20,
        model: 'Model T',
        partname: 'Part name T',
        partno: 'Part no T',
        price: '1000',
        lotsize: '10',
        unit: 'Kg',
        max: '10',
        min: '100',
        status: 'Disable',
        avatar: 'assets/images/avatars/6.png'
      }
     
    ];
  }
  