export class SparepartFakeData {
    public static spareparts = [
        {
            id: 1,
            code:'0001',
            name: 'แบตเตอรี่',
            catalog: 'ชิ้นส่วนในรถยนต์',
            spec: 'N/A',
            price: '1000',
            supplier: 'SSJ Technology Co.,Ltd.',
            contact: 'สุริยัน ศรีมาตย์',
            phone: '0982531845',
            location: 'กรุงเทพมหานคร',
            filenamedoc:'',
            filenamepic:'',
            status: 'Active',
            avatar: ''
          },
          {
            id: 2,
            code:'0002',
            name: 'ผ้าเบรค',
            catalog: 'ชิ้นส่วนช่วงล่าง',
            spec: 'N/A',
            price: '2000',
            supplier: 'SSJ Technology Co.,Ltd.',
            contact: 'สุริยัน ศรีมาตย์',
            phone: '0982531845',
            location: 'กรุงเทพมหานคร',
            filenamedoc:'',
            filenamepic:'',
            status: 'Active',
            avatar: ''
          },
          {
            id: 3,
            code:'0003',
            name: 'ไส้กรองอากาศ',
            catalog: 'ชิ้นส่วนในรถยนต์',
            spec: 'N/A',
            price: '3000',
            suppliername: 'SSJ Technology Co.,Ltd.',
            supplieraddress:'2/49 แขวงทับยาว เขตลาดกระบัง กรุงเทพมหานคร 10520',
            contact: 'สุริยัน ศรีมาตย์',
            phone: '0982531845',
            location: 'กรุงเทพมหานคร',
            filenamedoc:'',
            filenamepic:'',
            status: 'Active',
            avatar: ''
          },
          {
            id: 4,
            code:'0004',
            name: 'น้ำมันเกียร์และไส้กรอง',
            catalog: 'ชิ้นส่วนในรถยนต์',
            spec: 'N/A',
            price: '2000',
            filenamedoc:'',
            filenamepic:'',
            status: 'Active',
            avatar: ''
          }
    ];
  }
  