export class CatalogFakeData {
    public static catalogs = [
      {
        id: 1,
        catalogname: 'Catalog A',
      },
      {
        id: 2,
        catalogname: 'Catalog B',
      },
      {
        id: 3,
        catalogname: 'Catalog C',
      },
      {
        id: 4,
        catalogname: 'Catalog D',
      },
      {
        id: 5,
        catalogname: 'Part name E',
      },
      {
        id: 6,
        catalogname: 'Part name F',
      },
      {
        id: 7,
        catalogname: 'Part name G',
      },
      {
        id: 8,
        catalogname: 'Part name H',
      },
      {
        id: 9,
        catalogname: 'Part name I',
      },
      {
        id: 10,
        catalogname: 'Part name J',
      },
      {
        id: 11,
        catalogname: 'Part name J',
      },
      {
        id: 12,
        catalogname: 'Part name J',
      },
      {
        id: 13,
        catalogname: 'Part name J',
      },
      {
        id: 14,
        catalogname: 'Part name J',
      },
      {
        id: 15,
        catalogname: 'Part name J',
      },
      {
        id: 16,
        catalogname: 'Part name J',
      },
      {
        id: 17,
        catalogname: 'Part name J',
      },
      {
        id: 18,
        catalogname: 'Part name J',
      },
      {
        id: 19,
        catalogname: 'Part name J',
      },
      {
        id: 20,
        catalogname: 'Part name J',
      }

     
    ];
  }
  