export const locale = {
  lang: "en",
  data: {
    MENU: {
      OVERVIEW: {
        OVERVIEW: "Overview",
        SUMMARY: "Summary",
      },
      APPS: {
        MESSAGES: "Messages",
        BUILDING: "Building",
        VILLAGE: "Village",
        RESEVED: "Reseved",
        PORT: "Port",
        USERS: "User management",
        SETTINGS: "Settings",
        LOGOUT: "Logout",
      },
    },
  },
};
